<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <div class="promptReview">
        <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
          <a-form-item label="关键词" name='keywords'>
            <a-input v-model:value="search.keywords" placeholder=""></a-input>
          </a-form-item>
          <a-form-item label="审核状态" name='status'>
            <a-select v-model:value="search.status" :style="{width:'180px'}" placeholder="全部">
              <a-select-option value="">
                全部
              </a-select-option>
              <a-select-option :value="1">
                审核中
              </a-select-option>
              <a-select-option :value="0">
                已通过
              </a-select-option>
              <a-select-option :value="-1">
                已拒绝
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="创建日期" name='time'>
            <a-range-picker valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" htmlType='submit'>搜索</a-button>
          </a-form-item>
        </a-form>
        <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data'>
        </a-table>
         <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
      </div>
    </a-card>
  </a-layout>
</template>
<script>
import { onMounted, ref } from "vue";
const def = {
  status: "",
  keywords: "",
  start_time: "",
  end_time: "",
  page: 1,
  limit: 10,
};
const columns = [
  {
    title: "品牌商编号",
    dataIndex: "brand_no",
    key: "brand_no",
  },
  {
    title: "公司名称",
    dataIndex: "brand_no",
    key: "brand_no",
  },
  {
    title: "品牌商账号",
    dataIndex: "brand_no",
    key: "brand_no",
  },
  {
    title: "申请扫码模板防伪提示内容",
    dataIndex: "brand_no",
    key: "brand_no",
  },
  {
    title: "申请时间",
    dataIndex: "brand_no",
    key: "brand_no",
  },
  {
    title: "阿里云审核",
    dataIndex: "brand_no",
    key: "brand_no",
  },
];
export default {
  setup() {
    // 搜索的表单
    const search = ref(def);
    const total=   ref(0);
    const listLoading = ref(true); //加载状态
    const initData = () => {
      listLoading.value = false;
    };
    onMounted(() => {
      initData();
    });
    const pageSearchChange = () => {
      search.value.page = 1;
      initData();
    };
    // 切换时间
    const onChange = (e) => {
      if (e.length > 0) {
        search.value.start_time = e[0];
        search.value.end_time = e[1];
      } else {
        search.value.start_time = "";
        search.value.end_time = "";
      }
    };
        // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData();
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.limit = size;
      initData();
    };
    return {
      search,
      pageSearchChange,
      onChange,
      listLoading,
      columns,
      pageSizeChange,
      pageCurrentChange
    };
  },
};
</script>
<style lang="less" scoped>
</style>